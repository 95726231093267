import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ListItemPedidos from 'Components/OrderListComponents/ListItemPedidos';
import './ListPedidos.scss';

export const listPedidosSkeleton = (numeroItens) => {
  const skeleton = [];
  for (let i = 0; i < numeroItens; i += 1) {
    skeleton.push(<ListItemPedidos key={i} />);
  }
  return <div className="list-skeleton-wrapper">{skeleton}</div>;
};
class ListPedidos extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderList = (data, dealer, isPlanAhorro) =>
    data.map((item) => <ListItemPedidos item={item} key={item.orderId} dealer={dealer} isPlanAhorro={isPlanAhorro} />);

  render = () => {
    const { data, dealer = false, isPlanAhorro = false } = this.props;
    // Filtra os dados removendo os duplicados com base no 'orderId'
    const uniqueData = data.filter((item, index, self) => index === self.findIndex((t) => t.orderId === item.orderId));
    return <>{this.renderList(uniqueData, dealer, isPlanAhorro)}</>;
  };
}

ListPedidos.propTypes = {
  data: PropTypes.array.isRequired,
  dealer: PropTypes.bool,
  isPlanAhorro: PropTypes.bool,
};

export default ListPedidos;
