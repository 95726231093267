import React, { Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ReactComponent as LogoIcon } from 'Common/Assets/logo_stellantis_simbolo.svg';
import { ReactComponent as LogoutIcon } from 'Common/Assets/logout.svg';

import { environment, roleConsts } from 'Utils/consts';
import { User } from 'Common/Helpers/AuthHelper';
import langValues from 'Config/i18n/index';
import ToggleEnvironment from 'Components/Atoms/ToggleEnvironment/ToggleEnvironment';
import ToggleUser from 'Components/Atoms/ToggleUser/ToggleUser';
import DelayNotificationsButton from 'Components/Atoms/DelayNotificationsButton';
import NotificationButton from 'Components/Atoms/NotificationButton';
import HelpButton from 'Components/HelpComponents/HelpButton';
import PopUpMenu from '../PopUpMenu';

import './Menu.scss';

function logoutPingFederate(props) {
  const logoutUrl = 'https://idfed-preprod.mpsa.com:443/idp/startSLO.ping';

  fetch(logoutUrl, { method: 'POST', credentials: 'include' })
    .then(() => {
      props.history.push('/login');
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}

function Menu({ items, currentScreen, endMenu, selectMenu, loadLogout, settingsOptions }) {
  const renderSelectedBar = (item) => (item.id === currentScreen ? <div className="selectedRectangle" /> : '');

  const executeItemFunction = (method) => (method ? method() : null);

  const renderMenuItemText = (text) => (text ? <span className="font-small4">{text}</span> : null);

  const renderSubMenu = (item, index) => {
    if (item.subMenu) {
      return (
        <Link key={`subMenu-${index}`} to={item.path}>
          <item.subMenu changePage={() => selectMenu(item.id)} />
        </Link>
      );
    }
    return null;
  };

  const renderIconMenuItem = (item) => {
    const isSelected = item.id === currentScreen;

    return (
      <div
        key={`menu-item-${item.id}`}
        className={`menuItem ${isSelected ? 'selected' : ''}`} // Adiciona a classe 'selected'
        onClick={() => {
          console.log('aqui', item, item.subMenu);
          if (!item.subMenu) {
            selectMenu(item.id);
          }
          executeItemFunction(item.method);
        }}
      >
        {renderSelectedBar(item)}
        <div className="menuIconDiv">
          <item.icon className="menuIconElement" />
          {renderMenuItemText(langValues.menu[item.id])}
        </div>
      </div>
    );
  };

  const handleLogout = () => {
    loadLogout();
  };

  const handleLogoutPingFederate = () => {
    logoutPingFederate();
    loadLogout();
  };

  const renderMenuItem = (item, index) => {
    if (item.icon) {
      return (
        <Fragment key={`fragment-${index}`}>
          {!item.subMenu && (
            <Link key={`link-${index}`} to={item.path}>
              {renderIconMenuItem(item)}
            </Link>
          )}
          {item.subMenu && <>{renderIconMenuItem(item)}</>}
          {renderSubMenu(item, index)}
        </Fragment>
      );
    }
    return <NavLink to={item.path}>{item.name}</NavLink>;
  };

  const renderButonLogout = () => {
    const dealer = localStorage.getItem('dealer');
    const parsedData = JSON.parse(dealer);
    return parsedData && parsedData.userDpr.startsWith('PingFederate_') ? (
      <div className="menuItem" onClick={handleLogoutPingFederate}>
        <div className="menuIconDiv" id="logoutIcon">
          <LogoutIcon className="menuIconElement" />
        </div>
      </div>
    ) : (
      <div className="menuItem" onClick={handleLogout}>
        <div className="menuIconDiv" id="logoutIcon">
          <LogoutIcon className="menuIconElement" />
        </div>
      </div>
    );
  };

  const renderSettings = () => {
    const settings = settingsOptions.reduce((acc, cur, index) => {
      acc[index] = cur;
      return acc;
    }, []);
    if (User && User.get() && (User.hasProfile(roleConsts.admin) || User.hasProfile(roleConsts.customercare))) {
      return (
        <div className="menuItem settings">
          <PopUpMenu options={settings} selectMenu={selectMenu} currentScreen={currentScreen} />
        </div>
      );
    }
    return null;
  };

  const renderMenuItens = (elements) =>
    elements
      ? elements.map((element, index) => {
          if (User.hasOneOfProfiles(element.permittedProfiles)) {
            return renderMenuItem(element, index);
          }
          return null;
        })
      : null;

  return (
    <div className="menu">
      <div className="menuLogoDiv">
        <LogoIcon />
      </div>
      {renderMenuItens(items)}
      <div className="endMenuItems">
        <NotificationButton />
        <ToggleUser profilesList={[roleConsts.customercare, roleConsts.admin]}>
          <ToggleEnvironment env={environment.BR}>
            <DelayNotificationsButton />
          </ToggleEnvironment>
        </ToggleUser>
        <HelpButton />
        {renderMenuItens(endMenu)}
        {renderSettings()}
        {renderButonLogout()}
      </div>
    </div>
  );
}

Menu.propTypes = {
  items: PropTypes.array.isRequired,
  endMenu: PropTypes.array.isRequired,
  settingsOptions: PropTypes.array.isRequired,
  currentScreen: PropTypes.string.isRequired,
  selectMenu: PropTypes.func.isRequired,
  loadLogout: PropTypes.func.isRequired,
};

export default Menu;

