export const userEntryStyles = () => ({
  root: {
    marginLeft: '-8px',
    marginRight: '40px',
  },
  group: {
    display: 'inline',
  },
  paper: {
    width: '324px',
    boxShadow: 'none',
    borderWidth: '0px 0px 0px 1px',
    borderStyle: 'solid',
    borderColor: '#758CA5',
  },
  label: {
    typography: {
      color: 'green',
    },
  },
  radio: {
    '&$checked': {
      color: '#657DD9',
    },
  },
  checked: {},
});

export const textFieldStyles = {
  height: '14px',
  marginBottom: '32px',
  verticalAlign: 'bottom',
};

export const textFieldInputProps = {
  style: {
    color: '#000000',
    fontSize: '16px',
  },
};

export const getLoginButtonStyles = (loginLoading) => ({
  height: '40px',
  width: '150px',
  borderRadius: '3px',
  backgroundColor: loginLoading ? '#BABEC9' : '#657DD9',
});

export const getLoginButtonStylesConc = (loginLoading) => ({
  height: '60px',
  width: '250px',
  borderRadius: '3px',
  color: '#fff',
  backgroundColor: loginLoading ? '#BABEC9' : '#657DD9',
});

export const getLoginButtonStylesConcMarcas = (loginLoading) => ({
  height: '60px',
  width: '250px',
  borderRadius: '3px',
  color: '#fff',
  backgroundColor: loginLoading ? '#BABEC9' : '#657DD9',
  fontWeight: 'normal',
});

export default null;
