/* eslint-disable class-methods-use-this */
import AuthRepository from 'Infrastructure/Repositories/AuthRepository';
import DealerRepository from 'Infrastructure/Repositories/DealerRepository';
import RequestRepository from 'Infrastructure/Repositories/RequestRepository';
import { roleConsts } from 'Utils/consts';
import { User, Dealer, Token } from 'Common/Helpers/AuthHelper';

class AuthService {
  constructor(authRepository = new AuthRepository(), dealerRepository = new DealerRepository()) {
    this.authRepository = authRepository;
    this.dealerRepository = dealerRepository;
  }

  async login({ userName, password, userType, history, dealerUserId }) {
    try {
      const { data } = dealerUserId
        ? {
            data: {
              success: true,
              tokenType: 'bearer',
              userProfile: [roleConsts.dealers],
              userDpr: dealerUserId,
            },
          }
        : await this.authRepository.post({ userName, password, userType });

      if (data) {
        const response = { userData: data };
        if (userType === roleConsts.dealers) {
          const { userDpr } = data;
          const resDealer = await this.dealerRepository.get(userDpr);
          const dealerData = { ...resDealer.data };
          response.dealerData = { ...dealerData, userDpr };
          response.userData = {
            ...data,
            nome: dealerData.userName,
            initials: this.authRepository.getInitials(dealerData.userName),
            email: dealerData.email,
            token: dealerData.dealerToken,
            brand: dealerData.brandName,
            dealer: {
              dealerName: dealerData.dealerName,
              dealerId: dealerData.dealerName,
            },
            regional: {
              regionalName: dealerData.regionalName,
              regionalId: dealerData.regionalId,
            },
            sector: {
              regionalName: dealerData.sectorName,
              regionalId: dealerData.sectorId,
            },
          };
        }
        return Promise.resolve(response);
      }
      throw Error('Login error');
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async employeeUserLogin({ userName, password, userType }) {
    try {
      const { data } = await this.authRepository.post({ userName, password, userType });
      if (data) {
        const response = { userData: data };
        return Promise.resolve(response);
      }
      throw Error('Login error');
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async employeeCpfLogin(employeeCPF) {
    try {
      const data = await RequestRepository.getEmployee(employeeCPF);
      const userProfile = [roleConsts.employees];
      const name = data.name ? data.name : 'Funcionário';
      const initials = this.authRepository.getInitials(name);
      const response = {
        userData: {
          success: true,
          token: employeeCPF,
          tokenType: '',
          nome: name,
          initials,
          userProfile,
          email: name,
          brand: '',
        },
      };
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async clientLogin(clientDocument, orderCode) {
    try {
      await RequestRepository.clientAuth(clientDocument, orderCode);
      const userProfile = [roleConsts.client];
      const response = {
        userData: {
          success: true,
          clientDocument,
          orderCode,
          userProfile,
        },
      };
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async logout() {
    try {
      const { data } = await this.authRepository.logout();
      return Promise.resolve(data);
    } catch (err) {
      throw Promise.reject(err);
    }
  }

  async checkAuthState() {
    try {
      let data = false;
      const customercareAuth = await this.authRepository.checkAuthState();
      const dealerAuth = Dealer.get();
      const employeeAuth = Token.get();
      if (User.get() && (customercareAuth || dealerAuth || employeeAuth)) {
        data = true;
      }
      return data;
    } catch (err) {
      throw Promise.reject(err);
    }
  }

  async checkCaptcha(captchaToken) {
    try {
      const response = await this.authRepository.checkCaptcha(captchaToken);
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}

export default AuthService;
